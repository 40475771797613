import { Injectable } from '@angular/core'
import type { HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { HttpClient } from '@angular/common/http'
import type { Observable } from 'rxjs'
import { throwError } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { LoginService } from './login.service'

/**
 * All API services extend from this.
 *
 * -Wraps/Unwraps requests/responses with the GenericResponse
 * -Error handling
 */
@Injectable({
    providedIn: 'root',
})
export class APIServiceService {
    constructor(private http: HttpClient, private loginService: LoginService) {}

    public getGenericResponse<S, F>(
        url: string,
        paramsVal?: { [param: string]: string }
    ): Observable<GenericResponse<S, F>> {
        return this.get<GenericResponse<S, F>>(url, paramsVal).pipe(
            map((gr) => GenericResponse.copyToClass(gr)),
            catchError((e: HttpErrorResponse) => {
                if (e.error.success === false) {
                    return throwError(() =>
                        GenericResponse.copyToClass<S, F>(e.error)
                    )
                }
                return throwError(() => e.status + ' ' + e.error)
            })
        )
    }

    public putGenericResponse<S, F>(
        url: string,
        body: any,
        paramsVal?: { [param: string]: string }
    ): Observable<GenericResponse<S, F>> {
        return this.put<GenericResponse<S, F>>(url, body, paramsVal).pipe(
            map((gr) => GenericResponse.copyToClass(gr)),
            catchError((e: HttpErrorResponse) => {
                if (e.error.success === false) {
                    return throwError(() =>
                        GenericResponse.copyToClass<S, F>(e.error)
                    )
                }
                return throwError(() => e.status + ' ' + e.error)
            })
        )
    }

    public postGenericResponse<S, F>(
        url: string,
        body: any,
        options?: RequestOptions
    ): Observable<GenericResponse<S, F>> {
        return this.post<GenericResponse<S, F>>(url, body, options).pipe(
            map((gr) => GenericResponse.copyToClass(gr)),
            catchError((e: HttpErrorResponse) => {
                if (e.error.success === false) {
                    return throwError(() =>
                        GenericResponse.copyToClass<S, F>(e.error)
                    )
                }
                return throwError(() => e.status + ' ' + e.error)
            })
        )
    }

    public get<T>(
        url: string,
        paramsVal?: { [param: string]: string }
    ): Observable<T> {
        const httpOptions = {
            withCredentials: true,
            params: paramsVal,
        }

        return this.loginService.login().pipe(
            switchMap((login) => {
                return this.http.get<T>(url, httpOptions)
            })
        )
    }

    public post<T>(
        url: string,
        body: any,
        options?: RequestOptions
    ): Observable<T> {
        const httpOptions = {
            withCredentials: true,
            ...options,
        }
        return this.loginService.login().pipe(
            switchMap((login) => {
                return this.http.post<T>(url, body, httpOptions)
            })
        )
    }

    public put<T>(
        url: string,
        body: any,
        options?: RequestOptions
    ): Observable<T> {
        const httpOptions = {
            withCredentials: true,
            ...options,
        }
        return this.loginService.login().pipe(
            switchMap((login) => {
                return this.http.put<T>(url, body, httpOptions)
            })
        )
    }
}

export class GenericResponse<S, F> {
    success: boolean
    failureReason: string
    successData: S
    failureData: F

    static copyToClass<S, F>(gr: GenericResponse<S, F>) {
        const r = new GenericResponse<S, F>()
        r.success = gr.success
        r.failureReason = gr.failureReason
        r.successData = gr.successData
        r.failureData = gr.failureData
        return r
    }

    handle(
        successHandler: (s: S) => any,
        failureHandler: (failureReason: string, f: F) => any
    ) {
        if (this.success) {
            successHandler(this.successData)
        } else {
            failureHandler(this.failureReason, this.failureData)
        }
    }

    handleSuccess(successHandler: (s: S) => any) {
        this.handle(successHandler, (fr, f) => {
            throw this
        })
    }

    map<R>(
        successHandler: (s: S) => R,
        failureHandler: (failureReason: string, f: F) => R
    ) {
        if (this.success) {
            return successHandler(this.successData)
        } else {
            return failureHandler(this.failureReason, this.failureData)
        }
    }

    mapSuccess<R>(successHandler: (s: S) => R) {
        return this.map(successHandler, (fr, f) => {
            throw this
        })
    }

    getOrThrow(): S {
        return this.mapSuccess((s) => s)
    }
}

interface RequestOptions {
    headers?: HttpHeaders
}
