import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatSelectModule } from '@angular/material/select'
import { MatOptionModule } from '@angular/material/core'
import { NavComponent } from './nav.component'
import { MatSidenavModule } from '@angular/material/sidenav'
import { RouterModule } from '@angular/router'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { LogoModule } from '../logo/logo.module'
@NgModule({
    declarations: [NavComponent],
    imports: [
        CommonModule,
        MatSelectModule,
        MatOptionModule,
        MatSidenavModule,
        RouterModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatBottomSheetModule,
        LogoModule,
    ],
    exports: [NavComponent],
})
export class NavModule {}
