import { Injectable } from '@angular/core'
import type { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { EventService } from '../event.service'
import { LoggingService } from '../services/logging/logging.service'
import { APIServiceService } from './apiservice.service'
import type { XMenuItem } from './menu.service'
import type { RestaurantLocationMin } from './location.service'
import type { Table } from './table.service'
import { API_ROUTES } from './api-routes'

@Injectable({
    providedIn: 'root',
})
export class CheckService {
    constructor(private api: APIServiceService) {}

    getChecksByNFCTag(nfc: string): Observable<Check[]> {
        const url = API_ROUTES.public.checks + 'table_nfc='
        return this.api.get<Check[]>(url + nfc)
    }

    getCheckByReceiptCode(receiptCode: string): Observable<Check> {
        const url = API_ROUTES.public.checks + '?code='
        return this.api
            .get<Check>(url + receiptCode)
            .pipe(map((checks) => checks[0]))
    }

    getCheckByUUID(uuid: CheckUUID): Observable<Check> {
        const url = API_ROUTES.public.checks + '/'
        return this.api.get<Check>(url + uuid)
    }

    getCheckByID(checkId: string): Observable<CheckIDResponse> {
        const url = API_ROUTES.v2.getCheckById + '/' + checkId
        return this.api.get<CheckIDResponse>(url)
    }
}

export interface XStructuredCheckResponse {
    check: XStructuredCheck //Should not use AgentCheck
    checkLocationCode: number //Should be in Check from XPOServer
}

//From Agent
//Shoud not be used!
export interface XStructuredCheck {
    locationCode: number

    checkName: string
    tableNum: string
    checkNum: string
    checkSeq: string
    closed: boolean
    hasAlcohol: boolean

    printedCheckByTable: string
    printedCheckBySeat: string

    totals: XTotals
}

export interface XTotals {
    amountdue: number
    servicecharge: number
    subtotal: number
    tax: number
}

export interface XStructureCheckDetail {
    internal: XInternal
    quantity: number
    amount: number
    ref: string
    cdMenuItem: XStructureCheckDetailMenuItem
    cdDiscount: XStructureCheckDetailDiscount
    cdTender: XStructureCheckDetailTender
    children: XStructureCheckDetail[]
}

export interface XStructureCheckDetailMenuItem {
    miSeq: number
}
export interface XStructureCheckDetailTender {
    tmedSeq: number
}
export interface XStructureCheckDetailDiscount {
    dsvcSeq: number
}

export interface XInternal {
    uuid: string
    mi: XMenuItem
    total: number // The price for one item

    totalWithChildren: number // The price for one item with all children
    totalWithChildrenXQty: number // The price for qty items with all children
}

export interface XNFCResponse {
    location_id: number
    rvcseq: number
    tablenum: number
    tblseq: number
    checks: XCheck[]
}

export interface XChecks {
    checks: XSingleCheck[]
}

interface XSingleCheck {
    check: XCheck
    discounts: string[]
    print: XLine[]
    payment_options: XCheckPayInfo
    // Enhanced
    printString: string[]
}

export interface XCheck {
    total: number // Cents
    subtotal: number // Cents
    amount: number // Amount Due
    tax: number // Sum of taxes

    checknum: string
    checkseq: string
    checkname: string
    code: string
    merchant: number
    country: string
    currency: string
    location: number
    locationcode: number
    square_location_id: string
    tablenum: string
    checktime: string
    closed: boolean
    server: string // EmployeeName
    uuid: CheckUUID
    orderTypeSeq: number
    hasAlcohol: boolean
    // Sometimes there... sometimes with XSingleCheck
    print: XLine[]
    discounts: string[]

    // Enhanced
    printString: string[]
    payment_options: XCheckPayInfo
}

export interface XCheckPayInfo {
    maxCreditCard: number
    maxGiftCard: number
    maxWallet: number

    tipPercentDefault: number
    tipPercentChoices: number[]
}

export interface XLine {
    line: string
}

//NEW PUBLIC API

export interface Check extends CheckMin {
    readonly location: RestaurantLocationMin
    readonly table?: Table
    readonly prints: { readonly by_table: string; readonly by_seat: string }
    readonly revenue_center: { readonly name: string }
    readonly updated_at: string
    readonly is_payment_possible: boolean
    readonly processor: string
    readonly amounts: {
        readonly amount_due: string
        readonly servicecharge: string
        readonly subtotal: string
        readonly tax: string
        readonly total: string
    }
}

export interface CheckMin {
    readonly uuid: CheckUUID
    readonly closed: boolean
    readonly check_name: string
    readonly check_num: string
    readonly id: number
}

export type CheckUUID = string

// For backwards compatibility with QR code redirects using check_id not check uuid
// We only care about uuid in response
interface CheckIDResponse {
    check: {
        uuid: string
    }
}

type Processor = 'STRIPE' | 'SQUARE'
