import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { environment } from '../../../environments/environment'
import { BehaviorSubject } from 'rxjs'
import { AuthService } from '../../api/auth.service'
import { User, UserResponse } from '../../api/login.service'

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private $currentUser = new BehaviorSubject<User | null>(null)

    shouldPersist = false

    hidePaymentTutorial = false
    constructor(
        private router: Router,
        private generalAuth: AuthService,
        private snackBar: MatSnackBar
    ) {
        if (window.localStorage.getItem('hide-payment-tutorial')) {
            this.hidePaymentTutorial = JSON.parse(window.localStorage.getItem('hide-payment-tutorial')!)
        }
    }

    login(
        loginResponse: UserResponse,
        persist: boolean,
        afterAuth: () => void
    ) {
        const user: User = {
            email: loginResponse.email,
            firstname: loginResponse.firstname,
            lastname: loginResponse.lastname,
            mobilephone: loginResponse.mobilephone,
            uuid: loginResponse.uuid,
            brand: loginResponse.brand,
        }
        this.shouldPersist = persist
        this.setCurrentUserToLocalState(user)
        afterAuth()
        this.snackBar.open(`Logged in as ${user.email}`, 'Dismiss', {
            duration: environment.modalShort,
        })
    }

    get currentUser() {
        this.$currentUser.subscribe((userInService) => {
            if (!userInService) {
                let storedUser: User | null = null
                if (window.localStorage.getItem('user')) {
                    storedUser = JSON.parse(window.localStorage.getItem('user')!)
                } else if (window.sessionStorage.getItem('user')) {
                    storedUser = JSON.parse(window.sessionStorage.getItem('user')!)
                }
                if (storedUser) {
                    this.$currentUser.next(storedUser)
                }
            }
        })
        return this.$currentUser.asObservable()
    }

    logout() {
        this.generalAuth.logout().subscribe(() => {
            this.removeUserFromLocalState()
            this.router.navigateByUrl('/')
            this.snackBar.open('You have been logged out.', 'Dismiss', {
                duration: environment.modalShort,
            })
        })
    }

    private setCurrentUserToLocalState(currentUser: User) {
        if (this.shouldPersist) {
            window.localStorage.setItem('user', JSON.stringify(currentUser))
        } else {
            window.sessionStorage.setItem('user', JSON.stringify(currentUser))
        }
        this.$currentUser.next(currentUser)
    }

    private removeUserFromLocalState() {
        window.localStorage.removeItem('user')
        window.sessionStorage.removeItem('user')
        this.$currentUser.next(null)
    }


    setHidePaymentTutorialForFutureVisits() {
        window.localStorage.setItem('hide-payment-tutorial', 'true')
    }
}
