// @ts-strict-ignore
import type { OnInit } from '@angular/core'
import { Component, Inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MatSnackBar } from '@angular/material/snack-bar'
import { HttpErrorResponse } from '@angular/common/http'
import { LoggingService } from '../services/logging/logging.service'
import type { Check } from '../api/check.service'
import { CheckService } from '../api/check.service'
import type { RestaurantLocation, XLocation } from '../api/location.service'
import { LocationService } from '../api/location.service'
import { filter, map, switchMap, take } from 'rxjs/operators'
import { ConfigService } from '../api/config.service'
import { CartServiceService } from '../cart-service.service'
import { DOCUMENT } from '@angular/common'
import type { Table } from '../api/table.service'
import { TableService } from '../api/table.service'
import { iif, Observable, merge } from 'rxjs'
import { environment } from '../../environments/environment'
@Component({
    selector: 'app-tag-redirect',
    templateUrl: './tag-redirect.component.html',
    styleUrls: ['./tag-redirect.component.scss'],
})
export class TagRedirectComponent implements OnInit {
    uuid = this.activatedRoute.snapshot.paramMap.get('uuid')
    constructor(
        private api: LocationService,
        private apiConfig: ConfigService,
        private apiCheckService: CheckService,
        private cartService: CartServiceService,
        private router: Router,
        private snackBar: MatSnackBar,
        private logger: LoggingService,
        private activatedRoute: ActivatedRoute,
        private apiTableService: TableService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit(): void {
        this.logger.info('TagRedirect', 'Tapped tag: ' + this.uuid)
        this.getChecksOrTable().subscribe({
            next: (response) => {
                if ('checks' in response) {
                    this.hanldeTableWithChecks(response.loc, response.checks)
                } else {
                    this.handleNoChecks(response[0])
                }
            },
            error: (e) => this.handleError(e),
        })
    }

    private getChecksOrTable() {
        const checks$ = this.apiCheckService.getChecksByNFCTag(this.uuid).pipe(
            filter((checks) => checks.length > 0),
            switchMap((checks) => {
                return this.api
                    .getLocationById(checks[0].location.id)
                    .pipe(map((loc) => ({ checks, loc })))
            })
        )
        const tables$ = this.apiCheckService.getChecksByNFCTag(this.uuid).pipe(
            filter((checks) => checks.length === 0),
            switchMap(() => this.apiTableService.getTablesByNFC(this.uuid))
        )
        return merge(checks$, tables$)
    }

    private handleError(error) {
        this.router.navigate(['/'])
        if (error instanceof HttpErrorResponse) {
            this.snackBar.open(error.error.message, 'Dismiss', {
                duration: 5000,
            })
        }
    }

    private handleNoChecks(loc?: Pick<Table, 'id' | 'name'>) {
        this.logger.info(
            'TagRedirect',
            'Found no checks on tag - redirect to internal menu'
        )
        if (loc) {
            this.apiTableService.getTableById(loc.id).subscribe({
                next: (table) => {
                    this.router.navigate([
                        '/menu/view/',
                        table.location.id,
                        environment.dineInMenuId,
                    ])
                },
                error: (e) => {
                    //NFC tag returned an invalid location ID
                    this.logger.warn(
                        'TagRedirect',
                        'A user scanned an NFC tag with an invalid location ID'
                    )
                    this.handleNFCError(
                        'Error processing NFC Tag. Please enter 6 digit receipt code'
                    )
                },
            })
        } else {
            //NFC is not registered, no table was returned by /api/public/tables
            this.logger.warn(
                'TagRedirect',
                'A user scanned an NFC tag that was not registered to a table'
            )
            this.handleNFCError(
                'NFC Tag not registered. Please enter 6 digit receipt code'
            )
        }
    }

    private hanldeTableWithChecks(
        loc: RestaurantLocation,
        checks: readonly Check[]
    ) {
        const check = checks[0]
        this.logger.addLocation(loc)
        this.logger.addTable('' + check.table.name)
        this.logger.addRvc('' + check.revenue_center.name)
        this.logger.info(
            'TagRedirect',
            'Found ' + checks.length + ' checks on tag'
        )
        this.router.navigate(['/pay/choose', this.uuid])
    }

    private handleNFCError(message: string) {
        this.router.navigate(['/'])
        this.snackBar.open(message, 'Dismiss', {
            duration: 5000,
        })
    }
}
