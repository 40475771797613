import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { FlexLayoutModule } from '@angular/flex-layout'
import { HolderComponent } from './holder.component'
import { MatCardModule } from '@angular/material/card'
import { HeaderImageModule } from '../header-image/header-image.module'

@NgModule({
    declarations: [HolderComponent],
    imports: [
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        FlexLayoutModule,
        HeaderImageModule,

        CommonModule,
    ],
    exports: [HolderComponent],
})
export class HolderModule {}
