import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LogoComponent } from './logo.component'
import { RouterModule } from '@angular/router'
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module'

@NgModule({
    declarations: [LogoComponent],
    imports: [CommonModule, RouterModule, SkeletonLoaderModule],
    exports: [LogoComponent],
})
export class LogoModule {}
