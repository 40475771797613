import type { OnInit } from '@angular/core'
import { Component } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { CheckService } from 'src/app/api/check.service'
import { LoggingService } from 'src/app/services/logging/logging.service'

@Component({
    selector: 'app-check-id-redirect',
    templateUrl: './check-id-redirect.component.html',
    styleUrls: ['./check-id-redirect.component.scss'],
})
export class CheckIdRedirectComponent implements OnInit {
    checkId = this.activatedRoute.snapshot.paramMap.get('loccode_id')
    constructor(
        private checkService: CheckService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private snackBar: MatSnackBar,
        private logger: LoggingService
    ) {}

    ngOnInit(): void {
        this.checkService.getCheckByID(this.checkId).subscribe({
            next: (response) => {
                this.router.navigate([`/pay/view/${response.check.uuid}`])
            },
            error: (err) => {
                this.logger.error('QR Code Redirect', err)
                this.snackBar.open('Check not found', 'Dismiss', {
                    duration: 5000,
                })
                this.router.navigate(['/'])
            },
        })
    }
}
