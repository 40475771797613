import { ElementRef, OnInit, ViewChild } from '@angular/core'
import { Component } from '@angular/core'
import { OverlayContainer } from '@angular/cdk/overlay'
import { environment } from '../environments/environment'
import { slideToRight, slideToLeft, slideUp } from './router.animations'
import { NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { transition, trigger } from '@angular/animations'
import { TitleService } from './title.service'

import { filter } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TermsSnackComponent } from './widget/terms-snack/terms-snack.component'
import { LoggingService } from './services/logging/logging.service'
import { v4 as uuid } from 'uuid'

@Component({
    selector: 'app-root',
    animations: [
        trigger('routeAnimation', [
            transition('* => LandingPage', slideUp()),

            transition('LandingPage => TakeoutLandingPage', slideToRight()),
            transition(
                'TakeoutLandingPage => DeliveryInfoPage',
                slideToRight()
            ),
            transition('DeliveryInfoPage => MenuPage', slideToRight()),
            transition('LandingPage => MenuPage', slideToRight()),
            transition('MenuPage => MenuGroupPage', slideToRight()),
            transition('MenuGroupPage => MenuPage', slideToLeft()),
            transition('MenuGroupPage => ItemConfigPage', slideToRight()),
            transition('ItemConfigPage => MenuGroupPage', slideToLeft()),
            transition('MenuGroupPage => CartPage', slideToRight()),
            transition('CartPage => MenuPage', slideToLeft()),
            transition('MenuPage => CartPage', slideToRight()),
            transition('CartPage => MenuGroupPage', slideToLeft()),
            transition('CartPage => CheckoutPage', slideToRight()),
            transition('CheckoutPage => CartPage', slideToLeft()),
            transition('CheckoutPage => TakeoutCompletedPage', slideToRight()),

            transition('LandingPage => MenuLanding', slideToRight()),
            transition('MenuLanding => MenuView', slideToRight()),
            transition('MenuView => MenuView', slideToRight()),
            transition('MenuView => MenuLanding', slideToLeft()),

            transition('LandingPage => PayEnterCode', slideToRight()),
            transition('PayEnterCode => PayView', slideToRight()),
            transition('PayView => PayEnterCode', slideToLeft()),
            transition('PayChoose => PayView', slideToRight()),
            transition('PayView => PayChoose', slideToLeft()),
            transition('PayView => PayCC', slideToRight()),
            transition('PayCC => PayView', slideToLeft()),
            transition('PayCC => PayReceipt', slideToRight()),
            transition('PayReceipt => PayView', slideToLeft()),

            transition('LandingPage => AuthLogin', slideToRight()),
            transition('LandingPage => TransactionHistoryView', slideToRight()),
            transition('PayEnterCode => TransactionHistoryView', slideToLeft()),
            transition(
                'TransactionHistoryView => PayEnterCode',
                slideToRight()
            ),
            transition('PayEnterCode => PayCC', slideToRight()),
            transition('TransactionHistoryView => AuthSignup', slideToLeft()),
            transition('TransactionHistoryView => AuthLogin', slideToLeft()),
            transition('AuthLogin => TransactionHistoryView', slideToRight()),
            transition('AuthLogin => AuthSignup', slideToLeft()),
            transition('AuthSignup => AuthLogin', slideToRight()),
            transition('AuthSignup => TransactionHistoryView', slideToRight()),
            transition('* => AuthSignup', slideUp()),
            transition('* => AuthLogin', slideUp()),
            transition('* => TransactionHistoryView', slideUp()),
            transition('* => PayEnterCode', slideUp()),
            transition('* => AuthResetPassword', slideUp()),
        ]),
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @ViewChild('routeContainer')
    routeContainer: ElementRef

    isLanding = false

    constructor(
        public titleService: TitleService,

        public overlayContainer: OverlayContainer,
        private router: Router,
        public snackBar: MatSnackBar,
        private logger: LoggingService
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle('', '')
        // Create a log stream if there isnt one already
        if (!this.logger.getLogStreamId) {
            this.logger.createStream(uuid())
        }

        const theme = environment.brand
        this.overlayContainer.getContainerElement().classList.add(theme)

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((route) => {
                const basePath = route['url']
                this.isLanding = basePath === '/'
            })
        if (!localStorage.getItem('accepted-policy')) {
            this.snackBar.openFromComponent(TermsSnackComponent, {
                verticalPosition: 'bottom',
                horizontalPosition: 'left',
            })
        }
    }

    // Need overflow hidden to prevent scroll bars appearing during
    // animations but cant have overflow: hidden with sticky toolbar in menu module
    // So add overflow only when animating
    animationStart() {
        this.scrollToTop()
        this.routeContainer.nativeElement.style.overflow = 'hidden'
    }

    animationEnd() {
        this.routeContainer.nativeElement.style.overflow = 'visible'
    }

    scrollToTop() {
        window.scrollTo(0, 0)
    }

    prepRouteState(outlet: RouterOutlet) {
        return (
            outlet &&
            outlet.activatedRouteData &&
            outlet.activatedRouteData['animation']
        )
    }
}

export interface IEnvironment {
    production: boolean
    googleAPIKey: string
    squareUrl: string
    stripeClientKey: string
    merchantId: 13
    processor: 'SQUARE' | 'STRIPE'
    brand: string
    title: string
    giftCardEnable: boolean
    takeoutEnable: boolean
    giftingEnable: boolean
    giftingMessageEnable: boolean
    giftingMessage: string
    payEnable: boolean
    dineInMenuEnable: boolean
    dineInMenuId: number //NEED ALL MENU CONFIGS
    takeoutMenuId: number
    loginEnable: boolean
    modalShort: 3000
    modalLong: 5000
    feeFreeThreshold: number
    feeFreeHintThreshold: number
    feeMenuId: number
    feeDsvcSeq: number
    feeMenuCustPayUnder: number
    discountRefetchDelay: 5000
    payTipDefaultPercent: number
    payTipPercentages: number[]
    deliveryDriverTipDefaultPercent: number
    deliveryDriverTipPercentages: number[]
    deliveryDriverTipMax: number
    deliveryLocationTipDefaultPercent: number
    deliveryLocationTipPercentages: number[]
    takeoutLocationTipDefaultPercent: number
    takeoutLocationTipPercentages: number[]
    driverTipDsvcSeq: number
    locationTipDsvcSeq: number

    titleFull: string
    mapsAPIKey: string
    firebase: {
        apiKey: string
        authDomain: string
        databaseURL: string
        projectId: string
        storageBucket: string
        messagingSenderId: string
        appId: string
        measurementId: string
    }
    dcimages: {
        steak: string
        wine: string
        bubbles: string
        beer: string
        spirits: string
        happy: string
        liquor: string
        game: string
        shares: string
        bowls: string
        plant: string
        burgers: string
        sweets: string
        kits: string
        market: string
        kids: string
    }
    alcoholProhibitedMajorGroups: string[]
    alcoholProhibitedLocationIds: number[]
    alcoholProhibitedMenuIds: number[]
    terms: string
}
