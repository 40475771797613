// @ts-strict-ignore
import { Injectable, OnDestroy } from '@angular/core'
import { StorageMap } from '@ngx-pwa/local-storage'
import { Observable, ReplaySubject } from 'rxjs'
import { BehaviorSubject, of } from 'rxjs'
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { EventService } from './event.service'
import { LoggingService } from './services/logging/logging.service'
import { APIServiceService } from './api/apiservice.service'
import type { XLocation } from './api/location.service'
import type { XUser, XUserAddress } from './api/user.service'
import type {
    XSendPaymentResponse,
    XGiftCardBalance,
    XPickupTime,
    XSendCheckResponse,
    XPaymentInformation,
    XDelivery,
} from './api/transaction.service'
import type {
    XInternal,
    XStructureCheckDetail,
    XStructureCheckDetailMenuItem,
} from './api/check.service'
import { environment } from '../environments/environment'
import type { XMenuItem } from './api/menu.service'
import { MenuService } from './api/menu.service'
import { UuidService } from './uuid-service'
import type { SQNonceResponse } from './widget/square-pay-form/types'
import { TakeoutRequest, WebCheckPostParams } from './api/takeout.service'

@Injectable({
    providedIn: 'root',
})
export class CartServiceService implements OnDestroy {
    private _cleanup$ = new ReplaySubject<boolean>(1)

    private readonly KEY_NAME = 'cart3'

    changesSubject$ = new BehaviorSubject<Cart>(null)

    changes$ = this.changesSubject$.pipe(filter((x) => !!x))

    feeMenuCustPayUnder: XMenuItem

    private currentCart: Cart

    constructor(
        private api: APIServiceService,
        private uuidService: UuidService,
        private storage: StorageMap,
        private logger: LoggingService,
        private menuAPI: MenuService,
        private eventService: EventService,
        private router: Router
    ) {
        // Load the cart on startup
        this.storage
            .get(this.KEY_NAME)
            .pipe(
                map((i) => i as Cart),
                map((c) => (!!c ? c : this.newCart())),
                tap((c) => {
                    //Backwards compat
                    if (!c.giftCards) {
                        c.giftCards = []
                    }
                    if (!c.creditCards) {
                        c.creditCards = []
                    }

                    // this.calcTotal(c);
                    this.changesSubject$.next(c)
                })
            )
            .pipe(takeUntil(this._cleanup$))
            .subscribe()

        this.changes$
            .pipe(
                // Now get the fee menu (if we have a location)
                filter((c) => !!c.location),
                switchMap((c) =>
                    this.menuAPI.getEntireMenu(
                        c.location.id,
                        environment.feeMenuId,
                        null
                    )
                ),

                // Only load the fee menu once
                take(1),
                tap((m) => {
                    this.feeMenuCustPayUnder = m.filter(
                        (mi) => mi.miseq === environment.feeMenuCustPayUnder
                    )[0]

                    // Once we have the fees, push the cart again
                    const c = this.changesSubject$.value
                    this.update(c)
                    this.changesSubject$.next(c)
                })
            )
            .pipe(takeUntil(this._cleanup$))
            .subscribe()

        this.changes$.pipe(takeUntil(this._cleanup$)).subscribe((c) => {
            this.currentCart = c
        })
    }

    ngOnDestroy(): void {
        this._cleanup$.next(true)
        this._cleanup$.complete()
    }

    isValid(c: Cart): boolean {
        const young =
            new Date().getTime() - c.initTimestamp.getTime() < 1000 * 60 * 60
        const empty = c.items.length === 0
        const hasLoc = !!c.location
        return (empty || young) && hasLoc
    }

    reset(): Observable<Cart> {
        return this.update(this.newCart())
    }

    resetItems(c: Cart): Observable<Cart> {
        c.items = []
        c.initTimestamp = new Date()
        return this.update(c)
    }

    resetInitTimestamp(c: Cart): Observable<Cart> {
        c.initTimestamp = new Date()
        return this.update(c)
    }

    setLocation(cart: Cart, l: XLocation) {
        cart.location = l
        this.logger.info('CartService', 'Setting location to ' + l.name)
        return this.update(cart)
    }

    setUserAddress(cart: Cart, l: XUserAddress) {
        cart.userAddress = l
        return this.update(cart)
    }

    setUser(cart: Cart, user: XUser) {
        cart.user = user
        return this.update(cart)
    }

    setTime(cart: Cart, l: XPickupTime) {
        cart.time = l
        return this.update(cart)
    }

    updateUser(cart: Cart, user: XUser) {
        cart.user = user
        return this.update(cart)
    }

    addAll(cart: Cart, items: XStructureCheckDetail[]): Observable<Cart> {
        items.forEach((item) => {
            item.internal.uuid = this.uuidService.genUUID()
            if (!cart) {
                cart = this.newCart()
            }
            cart.items.push(item)

            this.calcTotal(cart)
            this.eventService.addToCart(item, cart.location)
        })

        return this.update(cart)
    }

    removeAll(c: Cart, zeros: XStructureCheckDetail[]): Observable<Cart> {
        const uuids = zeros.map((z) => z.internal.uuid)

        zeros.forEach((z) => {
            this.eventService.removeFromCart(z, c.location)
        })

        c.items = c.items.filter((i) => !uuids.includes(i.internal.uuid))
        return this.update(c)
    }

    private update(cart: Cart): Observable<Cart> {
        this.calcTotal(cart)
        this.logger.addCart(cart)

        return this.storage.set(this.KEY_NAME, cart).pipe(
            map(() => cart),
            tap(() => this.changesSubject$.next(cart))
        )
    }

    get(): Observable<Cart> {
        return of(this.changesSubject$.value)
    }

    getItem(c: Cart, uuid: string): XStructureCheckDetail {
        return c.items.filter((i) => i.internal.uuid === uuid)[0]
    }

    updateQty(
        cart: Cart,
        itemIn: XStructureCheckDetail,
        qty: number
    ): Observable<Cart> {
        const item = cart.items.filter(
            (i) => i.internal.uuid === itemIn.internal.uuid
        )[0]
        if (item.quantity !== qty) {
            // Events
            const diff = qty - item.quantity
            if (diff > 0) {
                item.quantity = diff
                this.calcTotal(cart)
                this.eventService.addToCart(item, cart.location)
            } else {
                item.quantity = diff * -1
                this.calcTotal(cart)
                this.eventService.removeFromCart(item, cart.location)
            }

            item.quantity = qty
            this.calcTotal(cart)
            return this.update(cart)
        } else {
            return of(cart)
        }
    }

    clearTips(c: Cart): Observable<Cart> {
        c.tips.clear()
        return this.update(c)
    }

    updateTip(c: Cart, tip: TipHolder): Observable<Cart> {
        c.tips.set(tip.name, tip)
        this.calcTotal(c)
        return this.update(c)
    }

    containsGC(c: Cart, usage: GiftCardUsage): boolean {
        return c.giftCards.some(
            (existing) => existing.gc.account_no === usage.gc.account_no
        )
    }

    addGC(c: Cart, usage: GiftCardUsage): Observable<Cart> {
        this.removeGC(c, usage)

        c.giftCards.push(usage)
        this.calcTotal(c)
        return this.update(c)
    }

    removeGC(c: Cart, usage: GiftCardUsage): Observable<Cart> {
        c.giftCards = c.giftCards.filter(
            (g) => g.gc.account_no !== usage.gc.account_no
        )

        this.calcTotal(c)
        return this.update(c)
    }

    addCreditCard(c: Cart, usage: CreditCardUsage): Observable<Cart> {
        this.removeCreditCard(c, usage)

        c.creditCards.push(usage)
        this.calcTotal(c)
        return this.update(c)
    }

    clearCreditCard(c: Cart): Observable<Cart> {
        c.creditCards = []
        this.calcTotal(c)
        return this.update(c)
    }

    removeCreditCard(c: Cart, usage: CreditCardUsage): Observable<Cart> {
        c.creditCards = c.creditCards.filter(
            (g) =>
                g.sq.cardDetails.last_4 !== usage.sq.cardDetails.last_4 &&
                g.sq.cardDetails.card_brand !== usage.sq.cardDetails.card_brand
        )

        this.calcTotal(c)
        return this.update(c)
    }

    updateFee(c: Cart, name: string, tip: number): Observable<Cart> {
        c.fees.set(name, tip)
        this.calcTotal(c)
        return this.update(c)
    }

    setType(c: Cart, type: string): Observable<Cart> {
        c.type = type

        c.tips.clear()
        if (c.type === 'Delivery') {
            c.tips.set('Driver Tip', {
                name: 'Driver Tip',
                amount: 0,
                percentage: environment.deliveryDriverTipDefaultPercent / 100,
                defaultPercentages: environment.deliveryDriverTipPercentages,
                dsvcSeq: environment.driverTipDsvcSeq,
            })
            c.tips.set('Store Tip', {
                name: 'Store Tip',
                amount: 0,
                percentage: environment.deliveryLocationTipDefaultPercent / 100,
                defaultPercentages: environment.deliveryDriverTipPercentages,
                dsvcSeq: environment.locationTipDsvcSeq,
            })
        } else {
            c.tips.set('Tip', {
                name: 'Tip',
                amount: 0,
                percentage: environment.takeoutLocationTipDefaultPercent / 100,
                defaultPercentages: environment.takeoutLocationTipPercentages,
                dsvcSeq: environment.locationTipDsvcSeq,
            })
        }
        return this.update(c)
    }

    setGiftInfo(c: Cart, giftInfo: GiftInfo): Observable<Cart> {
        c.giftInfo = giftInfo
        return this.update(c)
    }

    isOverThreshold(cart: Cart) {
        return cart.subTotal >= environment.feeFreeThreshold
    }

    calcTotal(cart: Cart) {
        try {
            cart.hasAlcohol = false
            cart.hasFood = false

            // Total for each item
            cart.items.forEach((i) => {
                if (i.quantity > 0) {
                    cart.hasAlcohol = cart.hasAlcohol || i.internal.mi.isAlcohol
                    cart.hasFood = cart.hasFood || i.internal.mi.isFood
                }

                i.internal.totalWithChildren = i.internal.total

                // Children
                if (!!i.children) {
                    i.children.forEach((c) => {
                        c.internal.totalWithChildren = c.internal.total
                        c.internal.totalWithChildrenXQty =
                            c.quantity * c.internal.total
                        i.internal.totalWithChildren +=
                            c.internal.totalWithChildrenXQty
                    })
                }

                i.internal.totalWithChildrenXQty =
                    i.quantity * i.internal.totalWithChildren
            })

            cart.hasOnlyAlcohol = !cart.hasFood && cart.hasAlcohol

            cart.delivery = this.getPaymentResponseBalanceZero(cart)?.delivery
            cart.paymentInformation =
                this.getPaymentResponseBalanceZero(cart)?.paymentInformation

            // Subtotal
            cart.subTotal = cart.items
                .map((i) => i.internal.totalWithChildrenXQty)
                .reduce((a, b) => a + b, 0)

            // Taxes
            cart.taxes.clear()
            cart.items.forEach((i) => {
                // Item Taxes
                i.internal.mi.taxes.forEach((tax) => {
                    const prev = cart.taxes.get(tax.tax_description) || 0
                    const newTaxTotal = i.quantity * tax.tax_amt
                    cart.taxes.set(tax.tax_description, prev + newTaxTotal)
                })

                // Child Taxes
                if (!!i.children) {
                    i.children.forEach((c) => {
                        c.internal.mi.taxes.forEach((tax) => {
                            const prev =
                                cart.taxes.get(tax.tax_description) || 0
                            const newTaxTotal =
                                i.quantity * c.quantity * tax.tax_amt
                            cart.taxes.set(
                                tax.tax_description,
                                prev + newTaxTotal
                            )
                        })
                    })
                }
            })

            // Add Fee & Taxes
            cart.feeToPay = 0
            if (
                !!this.feeMenuCustPayUnder &&
                cart.type === 'Delivery' &&
                environment.feeFreeThreshold > 0
            ) {
                if (!this.isOverThreshold(cart)) {
                    cart.feeToPay = this.feeMenuCustPayUnder.priceDollar
                    this.feeMenuCustPayUnder.taxes.forEach((tax) => {
                        const prev = cart.taxes.get(tax.tax_description) || 0
                        const newTaxTotal = tax.tax_amt
                        cart.taxes.set(tax.tax_description, prev + newTaxTotal)
                    })
                }
            }

            // TAXES ==================
            cart.taxes.forEach((amt, taxName) => {
                cart.taxes.set(
                    taxName,
                    Math.round((amt + Number.EPSILON) * 100) / 100
                )
            })
            const taxTotal = Array.from(cart.taxes.values()).reduce(
                (a, b) => a + b,
                0
            )
            cart.taxTotal = taxTotal

            // FEES ================
            cart.fees.forEach((amt, feeName) => {
                cart.fees.set(
                    feeName,
                    Math.round((amt + Number.EPSILON) * 100) / 100
                )
            })
            const feeTotal = Array.from(cart.fees.values()).reduce(
                (a, b) => a + b,
                0
            )

            // TIPS ========================
            cart.tips.forEach((th) => {
                th.amount =
                    Math.round(
                        (this.getBaseAmountThatTipsAreBasedOn(cart) *
                            th.percentage +
                            Number.EPSILON) *
                            100
                    ) / 100
            })

            const tipsTotal = Array.from(cart.tips.values()).reduce(
                (a, th) => a + th.amount,
                0
            )

            // TOTAL ====================
            cart.total =
                cart.taxTotal +
                cart.feeToPay +
                cart.subTotal +
                feeTotal +
                tipsTotal

            // GC =========
            cart.giftCards = cart.giftCards.filter((gc) => gc.amountToUse > 0)
            const gcTotal = cart.giftCards.reduce(
                (a, gc) => a + gc.amountToUse,
                0
            )

            // SQPayments =========
            cart.creditCards = cart.creditCards.filter((gc) => +gc.amount > 0)
            const sqPayTotal = cart.creditCards.reduce(
                (a, gc) => a + +gc.amount,
                0
            )

            // AmtDue =========
            const origAmountDue = cart.amountDue
            cart.amountDue = cart.total - gcTotal - sqPayTotal
            cart.amountDue =
                Math.round((cart.amountDue + Number.EPSILON) * 100) / 100

            // Use GC or calculate $1 =====================

            if (cart.giftCards.length > 0 && cart.amountDue !== 0) {
                const remainingFundsOnAllGCs = cart.giftCards.reduce(
                    (a, gc) => gc.gc.balance - gc.amountToUse,
                    0
                )
                const gcsWithRemainingFunds = cart.giftCards.filter(
                    (gc) => gc.gc.balance - gc.amountToUse > 0
                )
                const gcWithRemainingFunds =
                    gcsWithRemainingFunds[gcsWithRemainingFunds.length - 1]

                //TODO: Choose one that isnt maxed first?
                const gcWithPayment = cart.giftCards[cart.giftCards.length - 1]

                if (cart.amountDue < 0) {
                    const adj = Math.min(
                        gcWithPayment.amountToUse,
                        Math.abs(origAmountDue - cart.amountDue)
                    )
                    gcWithPayment.amountToUse -= adj

                    //Make one change at a time - Keep going...
                    this.calcTotal(cart)
                } else if (cart.amountDue < 1) {
                    const adj = Math.min(
                        gcWithPayment.amountToUse,
                        1 - cart.amountDue
                    )
                    gcWithPayment.amountToUse -= adj

                    //Make one change at a time - Keep going...
                    this.calcTotal(cart)
                }
            }

            if (
                cart.giftCards.length === 0 &&
                cart.amountDue != 0 &&
                cart.amountDue < 1
            ) {
                this.clearCreditCard(cart)
            }
        } catch (e) {
            this.eventService.exception(
                'cartCalculationError: ' + e.toString(),
                false
            )
            this.reset().pipe(takeUntil(this._cleanup$)).subscribe()
        }
    }

    getBaseAmountThatTipsAreBasedOn(cart: Cart) {
        return cart.subTotal + cart.taxTotal + cart.feeToPay
    }

    newItem() {
        return {
            internal: {} as XInternal,
            cdMenuItem: {} as XStructureCheckDetailMenuItem,
            children: [],
        } as XStructureCheckDetail
    }

    private newCart() {
        return {
            items: [],
            uuid: this.uuidService.genUUID(),
            fees: new Map<string, number>(),
            tips: new Map<string, TipHolder>(),
            total: 0,
            subTotal: 0,
            taxes: new Map<string, number>(),
            initTimestamp: new Date(),
            giftCards: [],
            creditCards: [],
        } as Cart
    }

    removeInternalsItems(items: XStructureCheckDetail[]) {
        items.forEach((i) => {
            if (!!i.children) {
                i.children.forEach((ic) => delete ic.internal)
            }
            delete i.internal
        })
    }

    cloneAndRemoveInternals(
        items: XStructureCheckDetail[]
    ): XStructureCheckDetail[] {
        const cdCopy = items.map((i) => {
            const i2 = Object.assign({}, i)
            if (!!i.children) {
                i2.children = this.cloneAndRemoveInternals(i.children)
                if (i2.children.length == 0) {
                    delete i2.children
                }
            }
            if (i2.ref == null) {
                delete i2.ref
            }
            if (i2.quantity === 1) {
                delete i2.quantity
            }
            return i2
        })

        this.removeInternalsItems(cdCopy)
        return cdCopy
    }

    getMaxAmountForGC(cart: Cart, gc: XGiftCardBalance): number {
        return Math.min(cart.amountDue, gc.balance)
    }

    updateResponse(c: Cart, r: XSendCheckResponse): Observable<Cart> {
        c.response = r
        return this.update(c)
    }
    updateResponseError(c: Cart, r: any): Observable<Cart> {
        c.responseError = r
        return this.update(c)
    }

    getPaymentResponseBalanceZero(cart: Cart): XSendPaymentResponse {
        const payments = [...cart.giftCards, ...cart.creditCards]
        const finishedPayment = payments
            .filter((x) => !!x.response)
            .filter((x) => +x.response.balanceOwing === 0)
            .map((x) => x.response)

        if (finishedPayment.length === 0) {
            return null
        }

        return finishedPayment[0]
    }
    // TakeoutRequest
    get getCartForApiCall(): TakeoutRequest {
        const { user, items } = this.currentCart

        return {
            items: items.map((item) => this.menuItemBuilder(item)),
        }
    }

    get getCartUserDetails(): CartUserDetails {
        if (this.currentCart.user) {
            return {
                email: this.currentCart.user.email,
                first_name: this.currentCart.user.firstName,

                last_name: this.currentCart.user.lastName,
                phone: this.currentCart.user.mobilePhone,
                pickup_time: this.currentCart.time.ts,
            }
        }
    }

    private menuItemBuilder(structuredCheckItem: XStructureCheckDetail) {
        return {
            menu_item: {
                miSeq: structuredCheckItem.internal.mi.miseq,
                menu_id: structuredCheckItem.internal.mi.id ?? null,
            },
            message: structuredCheckItem.ref,
            quantity: structuredCheckItem.quantity,
            children:
                structuredCheckItem.children?.map((item) =>
                    this.menuItemBuilder(item)
                ) ?? [],
        }
    }
}

export interface Cart {
    uuid: string
    initTimestamp: Date

    items: XStructureCheckDetail[]
    location: XLocation
    userAddress: XUserAddress
    user: XUser
    time: XPickupTime

    type: string // Pickup or Delivery
    giftInfo: GiftInfo //Type=Delivery and this is non-null

    taxes: Map<string, number>
    fees: Map<string, number>
    tips: Map<string, TipHolder>
    giftCards: GiftCardUsage[]
    creditCards: CreditCardUsage[]

    subTotal: number
    feeToPay: number
    taxTotal: number
    total: number
    amountDue: number

    hasAlcohol: boolean
    hasFood: boolean
    hasOnlyAlcohol: boolean

    response: XSendCheckResponse //Set after sending check
    responseError: any

    delivery: XDelivery //Set after last payment
    paymentInformation: XPaymentInformation //Set after last payment
}

export interface GiftInfo {
    nameFrom: string
    nameTo: string
    message: string
}

export interface TipHolder {
    name: string
    amount: number //Calculated by cart eg: (subtotal+tax) * percentage
    percentage: number //The value of .10 represents 10%
    defaultPercentages: number[] //[5,10,15]
    dsvcSeq: number
}

export interface GiftCardUsage {
    uuid: string
    amountToUse: number //Dollars
    gc: XGiftCardBalance
    response: XSendPaymentResponse
    responseError: any
}

export interface CreditCardUsage {
    uuid: string
    amount: number //Dollars //TODO: Amount here and also in sq.amount... :(
    sq: SQNonceResponse
    response: XSendPaymentResponse
    responseError: any
}

export interface CartUserDetails
    extends Pick<
        WebCheckPostParams,
        'email' | 'phone' | 'pickup_time' | 'first_name' | 'last_name'
    > {}
