import { Component, Input, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'
import { TitleService } from '../../title.service'

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
    brand = environment.brand + ' Logo'

    @Input()
    small = false

    title: string

    loading = true

    height = 'auto'

    width = 'auto'

    constructor(public titleService: TitleService) {
        this.title = this.titleService.getToolbar2()
    }

    ngOnInit(): void {
        this.height = this.small ? '40px' : 'auto'
        this.width = this.small ? 'auto' : '200px'
    }

    onLoad() {
        this.loading = false
    }
}
