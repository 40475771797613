import type { ErrorHandler } from '@angular/core'
import { Injectable } from '@angular/core'
import { EventService } from './event.service'
import { LoggingService } from './services/logging/logging.service'
import { HttpErrorResponse } from '@angular/common/http'
import { CartServiceService } from './cart-service.service'
import { environment } from '../environments/environment'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private cart: CartServiceService,
        private eventService: EventService,
        private logger: LoggingService
    ) {}

    handleError(error: any) {
        let message: string = error.message ? error.message : error.toString()

        try {
            if (message.includes('object Object')) {
                message = JSON.stringify(error)
            }
        } catch (x) {}

        // Reload on chunk load error
        if (message.includes('ChunkLoadError')) {
            window.location.reload()
            return
        }
        if (message.includes('The database connection is closing')) {
            window.location.reload()
            return
        }

        this.eventService.exception(message, true)
        // Todo: we dont want to send non-pertinent logs to aws -> handle this here
        /*  TODO: Rework this section to ensure that too many logs
        will not get sent to aws in the case of an inadvertent infine loop or some similar behavior */
        if (error instanceof Error) {
            /* this.logger.error(
                'GlobalErrorHandler',
                'Error:' +
                    error.message +
                    ' Name:' +
                    error.name +
                    ' Stack:' +
                    error.stack
            ) */
        } else if (error instanceof HttpErrorResponse) {
            /* this.logger.error(
                'GlobalErrorHandler',
                'HTTPError:' +
                    error.message +
                    ' Name:' +
                    error.name +
                    ' StatusText:' +
                    error.statusText +
                    ' StatusCode:' +
                    error.status
            ) */
        } else {
            /* this.logger.error(
                'GlobalErrorHandler',
                'NonError:' +
                    message +
                    ' Type:' +
                    error.constructor?.name +
                    ' toStr:' +
                    error
            ) */
        }

        if (environment.production) {
            // Clear data - start over
            this.cart.reset().subscribe()
        }

        throw error
    }
}
