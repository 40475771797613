// @ts-strict-ignore
import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import type { Observable } from 'rxjs'
import { combineLatest, EMPTY, of } from 'rxjs'
import {
    filter,
    map,
    onErrorResumeNext,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs/operators'
import { environment } from '../../environments/environment'

import { LoggingService } from '../services/logging/logging.service'
import { VERSION } from '../../environments/version'
import { APIServiceService } from './apiservice.service'
import type { XPickupTime } from './transaction.service'
import { API_ROUTES } from './api-routes'

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    // TODO: This sholud be done server side
    private kitsWithAlcoholAndFood = [
        15012, 15013, 15014, 15015, 15016, 15017, 15018, 10117, 10118, 10119,
        10120, 10121, 10122, 10123, 10124, 10125, 10129, 10130, 15107, 15108,
        15116, 14868, 15131, 15132, 15133, 10250, 10251, 10252,
    ]

    private miWithCondsCache = new Map<string, Observable<XMenuItem>>()
    private displayCatsCache = new Map<string, Observable<XDisplayCategory[]>>()
    private menuItemCache = new Map<string, Observable<XMenuItem[]>>()
    private entireMenuItemCacheByDC = new Map<string, Observable<DCWithMI[]>>()
    private entireMenuItemCache = new Map<string, Observable<XMenuItem[]>>()

    private menusCache$: Observable<XMenu[]> = this.getMenusRaw().pipe(
        shareReplay(1)
    )

    constructor(private api: APIServiceService) {}

    public getEntireMenu(
        locId: number,
        menuDefId: number,
        ts: XPickupTime
    ): Observable<XMenuItem[]> {
        if (!locId || !menuDefId) {
            return EMPTY
        }

        const key = locId + '-' + menuDefId + '-' + (!!ts ? ts.ts : '')

        if (!this.entireMenuItemCache.has(key)) {
            const tsParam = !!ts ? '&ts=' + ts.ts : ''

            const m = this.api
                .get<XMenuResponse>(
                    API_ROUTES.v3.locations +
                        '/' +
                        locId +
                        '/menus?menu_definition_id=' +
                        menuDefId +
                        '' +
                        tsParam
                )
                .pipe(
                    map((x) => {
                        // Enhance
                        // TODO: This should be done server side
                        x.menu.forEach((mi) => {
                            this.enhanceMenuItem(locId, menuDefId, mi)
                        })

                        // Filter out prohibited items for some locations/menus
                        if (
                            environment.alcoholProhibitedLocationIds.includes(
                                locId
                            ) &&
                            environment.alcoholProhibitedMenuIds.includes(
                                menuDefId
                            )
                        ) {
                            x.menu = x.menu.filter(
                                (mi) =>
                                    !environment.alcoholProhibitedMajorGroups.includes(
                                        mi.majorGroup
                                    )
                            )
                        }

                        return x.menu
                    }),
                    shareReplay(1)
                )

            this.entireMenuItemCache.set(key, m)
            return m
        }

        return this.entireMenuItemCache.get(key)
    }

    getEntireMenuByDC(
        locId: number,
        menuDefId: number,
        ts: XPickupTime
    ): Observable<DCWithMI[]> {
        const key = locId + '-' + menuDefId + '-' + (!!ts ? ts.ts : '')

        if (!this.entireMenuItemCacheByDC.has(key)) {
            const m = this.getEntireMenuByDC2(locId, menuDefId, ts).pipe(
                shareReplay(1)
            )
            this.entireMenuItemCacheByDC.set(key, m)
            return m
        }

        return this.entireMenuItemCacheByDC.get(key)
    }

    private getEntireMenuByDC2(
        locId: number,
        menuDefId: number,
        ts: XPickupTime
    ): Observable<DCWithMI[]> {
        const mis$ = this.getEntireMenu(locId, menuDefId, ts)
        const dc$ = this.getDisplayCats(locId, menuDefId, ts)

        // dc's are filtered based on time of day

        // Group by DC
        return combineLatest([dc$, mis$]).pipe(
            map(([dcs, mis]) => {
                let result: DCWithMI[] = []

                // Group by DC
                mis.forEach((mi) => {
                    mi.display.forEach((dcx) => {
                        const dcWithMis = result.filter(
                            (x) => x.dc.id === dcx.id
                        )
                        if (dcWithMis.length === 1) {
                            dcWithMis[0].mis.push(mi)
                        } else {
                            result.push({
                                dc: dcx,
                                mis: [mi],
                            } as DCWithMI)
                        }
                    })
                })

                // Replace DC with full info (Only keep DC's that are found)
                result = result.filter((dcwithmis) => {
                    const betterDC = dcs.find((x) => x.id === dcwithmis.dc.id)
                    if (!betterDC) {
                        // This category should not be shown right now
                        // TODO: Server should filter out menu items based on time of day, not just DCs
                        // TODO: Server should return entire DC data or just the DCID.. not just a few attributes
                    }
                    dcwithmis.dc = betterDC
                    return !!betterDC
                })

                // Sort DC and them Mis
                result.sort((a, b) => a.dc.sort_order - b.dc.sort_order)
                result.forEach((x) => {
                    x.mis.sort((a, b) => a.sort_order - b.sort_order)
                })

                return result
            })
        )
    }

    // getAllDisplayCategories(): Observable<XMenuItem[]> {
    //   return this.api.get<XMenuResponse>('/api/order/v0/menu/display').pipe(
    //     map(m => m.menu)
    //   );
    // }

    getMenus(): Observable<XMenu[]> {
        return this.menusCache$
    }

    private getMenusRaw(): Observable<XMenu[]> {
        return this.api
            .get<XMenusResponse>(API_ROUTES.v3.menuName)
            .pipe(map((r) => r.menus))
    }

    getMenu(menuId: number): Observable<XMenu> {
        return this.getMenus().pipe(
            map((menus) => menus.filter((m) => m.id === menuId)[0])
        )
    }

    enhanceMenuItem(locid: number, menuId: number, i: XMenuItem) {
        if (!!i.photos && i.photos.length > 0) {
            const url = i.photos[0].filename
            if (!!url) {
                const lastPart = url.substring(url.lastIndexOf('/') + 1)
                i.thumbPhoto = '/images/resized/thumb/' + lastPart
                i.thumbDarkPhoto = '/images/resized/thumbDark/' + lastPart
                i.medPhoto = '/images/resized/med/' + lastPart
                i.medDarkPhoto = '/images/resized/medDark/' + lastPart
            }
        }
        i.priceDollar = i.price / 100

        // TODO: server needs to send (isFood, isAlcohol, isLiquor)
        // Remember isFood and isAlcohol are not exclusive (eg: kit with food and beer)
        i.isFood = !i.isAlcohol
        if (this.kitsWithAlcoholAndFood.includes(i.miseq)) {
            i.isAlcohol = true
            i.isFood = true
        }

        // Show miseq/name1 if shortdesc not set!
        if (!i.shortDesc) {
            i.shortDesc = '!!! ' + i.name1 + ' (' + i.miseq + ') !!!'
        }

        // Recurse through condiments
        if (!!i.condiments) {
            // Filter
            i.condiments = i.condiments.filter((c) => {
                const reqByPOS = c.condGrpSeq >= 0 && c.min >= 1
                const fromYml = c.condGrpSeq < 0
                const hasMenuItems = !!c.menu_items && c.menu_items.length > 0
                return (reqByPOS || fromYml) && hasMenuItems
            })

            // Enhance
            i.condiments.forEach((c) => {
                if (!!c.menu_items) {
                    c.menu_items.forEach((m) =>
                        this.enhanceMenuItem(locid, menuId, m)
                    )
                    c.menu_items = this.filterOnlyVisibleToUser(
                        c.menu_items,
                        locid,
                        menuId
                    )
                }

                if (!c.min) {
                    c.min = 0
                }
                if (!c.max || c.max === 0) {
                    c.max = 99
                }
            })
        }
    }

    getMenuItems(
        locId: number,
        menuId: number,
        displayCatId: number
    ): Observable<XMenuItem[]> {
        const cacheKey = locId + '-' + menuId + '-' + displayCatId
        let val = this.menuItemCache.get(cacheKey)
        if (!!val) {
            return val
        }

        val = this.api
            .get<XMenuResponse>(
                API_ROUTES.v3.menu +
                    locId +
                    '/id/' +
                    menuId +
                    '/displaycategory/' +
                    displayCatId
            )
            .pipe(
                map((r) => r.menu),
                map((ms) => this.filterOnlyVisibleToUser(ms, locId, menuId)),
                tap((mi) =>
                    mi.forEach((m) => this.enhanceMenuItem(locId, menuId, m))
                ),
                shareReplay(1)
            )

        this.menuItemCache.set(cacheKey, val)
        return val
    }

    private filterOnlyVisibleToUser(
        ms: XMenuItem[],
        locId: number,
        menuId: number
    ) {
        return ms
            .filter((m) => !this.isProhibitedAtLoc(locId, menuId, m))
            .sort((a, b) => a.price - b.price)
            .sort((a, b) => a.sort_order - b.sort_order)
    }

    private isLiquor(mi: XMenuItem) {
        return mi.majorGroup === 'LIQUOR'
    }

    private isProhibitedAtLoc(locId: number, menuId: number, mi: XMenuItem) {
        // Remove Liquor from Kenniston/Polo/LocalGarry from Takeout
        return (
            menuId === environment.takeoutMenuId &&
            (locId === 18 || locId === 22 || locId === 42) &&
            this.isLiquor(mi)
        )
    }

    getMenuItemWithCondiments(
        locId: number,
        menuId: number,
        miid: number
    ): Observable<XMenuItem> {
        const cacheKey = locId + '-' + menuId + '-' + miid
        let val = this.miWithCondsCache.get(cacheKey)
        if (!!val) {
            return val
        }

        val = this.api
            .get<XMenuItemResponse>(
                API_ROUTES.v3.menu +
                    locId +
                    '/id/' +
                    menuId +
                    '/details/' +
                    miid
            )
            .pipe(
                map((r) => r.menu_item),
                tap((r) => this.enhanceMenuItem(locId, menuId, r)),
                shareReplay(1)
            )

        this.miWithCondsCache.set(cacheKey, val)

        return val
    }

    getDisplayCats(
        locId: number,
        menuDefnId: number,
        ts: XPickupTime | null
    ): Observable<XDisplayCategory[]> {
        if (!locId || !menuDefnId) {
            return EMPTY
        }

        const filterByTimeOfDay = !!ts ? '?ts=' + ts.ts : ''

        const cacheKey = locId + '-' + menuDefnId + '-' + filterByTimeOfDay
        let val = this.displayCatsCache.get(cacheKey)
        if (!!val) {
            return val
        }

        val = this.api
            .get<XDisplayCatsResponse>(
                API_ROUTES.v3.menu +
                    locId +
                    '/displaycategory/menu/' +
                    menuDefnId +
                    filterByTimeOfDay
            )
            .pipe(
                map((r) => r.display_categories),
                tap((dcs) => {
                    dcs.forEach((dc) => {
                        dc.image_url = this.getDCImageUrl(dc)
                    })
                }),
                shareReplay(1)
            )

        this.displayCatsCache.set(cacheKey, val)
        return val
    }

    getDCImageUrl(dc: XDisplayCategory) {
        const dcname = dc.name.toLowerCase()
        const keys = Object.keys(environment.dcimages)

        const key = keys.find((k) => {
            return dcname.includes(k)
        })

        if (!!key) {
            const url = environment.dcimages[key]
            return url
        }
        return null
    }

    getDisplayCat(
        locId: number,
        menuId: number,
        dcId: number,
        ts: XPickupTime
    ): Observable<XDisplayCategory> {
        return this.getDisplayCats(locId, menuId, ts).pipe(
            map((cats) => cats.filter((c) => c.id === dcId)[0])
        )
    }
}

export interface DCWithMI {
    dc: XDisplayCategory
    mis: XMenuItem[]
}

export interface XMenusResponse {
    menus: XMenu[]
}

export interface XMenu {
    id: number
    name: string
    description: string
}

export interface XMenuResponse {
    menu: XMenuItem[]
}

export interface XMenuItemResponse {
    menu_item: XMenuItem
}

export interface XMenuItem {
    id?: number
    miseq: number
    shortDesc: string
    longDesc: string
    name1: string
    price: number
    display: XDisplayCategory[]
    photos: XMenuPhoto[]
    isCondiment: boolean
    available: boolean
    taxes: XTax[]
    condiments: XCondiment[]

    familyGroup: string
    // type: string;
    // menu_type: string;
    majorGroup: string
    sort_order: number

    // Enhanced
    thumbPhoto: string
    thumbDarkPhoto: string
    medPhoto: string
    medDarkPhoto: string
    priceDollar: number
    isAlcohol: boolean
    isFood: boolean
}

export interface XCondiment {
    condGrpSeq: number
    name: string
    min: number
    max: number
    maxQty: number
    menu_items: XMenuItem[]
}

export interface XTax {
    tax_amt: number
    tax_description: string
}

export interface XMenuPhoto {
    filename: string
}

export interface XDisplayCatsResponse {
    display_categories: XDisplayCategory[]
}

export interface XDisplayCategory {
    id: number
    name: string
    description: string
    sort_order: number
    image_url: string
}
