import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class ScriptLoaderService {
    googleLoaded$ = new BehaviorSubject(false)
    squareLoaded$ = new BehaviorSubject(false)

    public googleLoaded(): BehaviorSubject<boolean> {
        return this.googleLoaded$
    }

    public squareLoaded(): BehaviorSubject<boolean> {
        return this.squareLoaded$
    }
}
