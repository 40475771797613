<main>
    <div class="filter"></div>
    <section>
        <app-logo></app-logo>
        <div class="button-wrap">
            <button mat-button routerLink="/pay" *ngIf="enablePay">
                Pay Bill
            </button>
            <button mat-button routerLink="/takeout" *ngIf="enableTakeout">
                Order Takeout
            </button>
        </div>
        <div class="bottom-logo">
            <p>Powered by</p>
            <img
                fetchpriority="high"
                alt="XPO"
                height="22px"
                width="75px"
                src="/assets/xpo-logo.png"
            />
        </div>
    </section>
</main>
<img
    src="/assets/background.jpg"
    fetchpriority="high"
    height="100vh"
    width="100vw"
    style="display: none"
    alt=""
/>
