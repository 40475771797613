import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { environment } from '../environments/environment'

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    private toolbar: string

    constructor(private title: Title) {}

    setTitle(toolbar: string, page: string) {
        this.title.setTitle(environment.title + ' ' + page)
    }

    getToolbar1() {
        return environment.title
    }

    getToolbar2() {
        return this.toolbar
    }
}
