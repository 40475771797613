import {
    BrowserModule,
    HAMMER_GESTURE_CONFIG,
    HammerGestureConfig,
    HammerModule,
} from '@angular/platform-browser'
import { ErrorHandler, Injectable, NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { MatButtonModule } from '@angular/material/button'
import { MatToolbarModule } from '@angular/material/toolbar'
import { FlexLayoutModule } from '@angular/flex-layout'
import { NgxSpinnerModule } from 'ngx-spinner'
import { MyHttpInterceptor } from './http-inteceptor'
import {
    AngularFireAnalyticsModule,
    ScreenTrackingService,
    UserTrackingService,
} from '@angular/fire/compat/analytics'
import { GlobalErrorHandler } from './error.handler'
import { LandingComponent } from './landing/landing.component'
import { TagRedirectComponent } from './tag-redirect/tag-redirect.component'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { HolderModule } from './widget/holder/holder.module'

import { StorageModule } from '@ngx-pwa/local-storage'
import { AccountDetailsComponent } from './user/account-details/account-details.component'
import { AngularFireModule } from '@angular/fire/compat'
import { environment } from '../environments/environment'
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance'
import { CommonDialogComponent } from './widget/common-dialog/common-dialog.component'
import { ScriptLoaderComponent } from './script-loader/script-loader.component'
import { NgxMaskModule } from 'ngx-mask'
import { MatIconModule } from '@angular/material/icon'
import { ReactiveFormsModule } from '@angular/forms'
import { SkeletonLoaderModule } from './widget/skeleton-loader/skeleton-loader.module'
import { RemovePaymentComponent } from './widget/remove-payment/remove-payment.component'
import { CheckIdRedirectComponent } from './widget/check-id-redirect/check-id-redirect.component'
import { NavModule } from './widget/nav/nav.module'
import { MatDialogModule } from '@angular/material/dialog'
import { TermsSnackComponent } from './widget/terms-snack/terms-snack.component'
import { LogoModule } from './widget/logo/logo.module'

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = {
        pinch: { enable: false },
        rotate: { enable: false },
    } as any
}

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        TagRedirectComponent,
        AccountDetailsComponent,
        CommonDialogComponent,
        ScriptLoaderComponent,
        RemovePaymentComponent,
        CheckIdRedirectComponent,

        TermsSnackComponent,
    ],
    imports: [
        AppRoutingModule,

        BrowserModule,
        BrowserAnimationsModule,
        SkeletonLoaderModule,
        MatButtonModule,
        MatToolbarModule,
        MatSnackBarModule,
        NgxSpinnerModule,
        MatIconModule,
        HammerModule,
        HttpClientModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        HolderModule,
        NgxMaskModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        AngularFirePerformanceModule,
        StorageModule,
        NavModule,
        MatDialogModule,
        LogoModule,
    ],
    providers: [
        { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MyHttpInterceptor,
            multi: true,
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        ScreenTrackingService,
        UserTrackingService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
