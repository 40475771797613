<a [routerLink]="['/']">
    <img
        [alt]="brand"
        src="/assets/main-logo.png"
        [ngClass]="small ? 'small' : 'large'"
        [hidden]="loading"
        [width]="width"
        [height]="height"
    />
    <img
        [hidden]="!loading"
        src="/assets/main-logo.png"
        fetchpriority="high"
        [ngClass]="small ? 'small' : 'large'"
        (load)="onLoad()"
        [width]="width"
        [height]="height"
    />
    <h1>{{ title }}</h1>
</a>
