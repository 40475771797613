import { Injectable } from '@angular/core'
import { StorageMap } from '@ngx-pwa/local-storage'
import { DeviceDetectorService } from 'ngx-device-detector'
import type { XLogin, UserResponse } from '../../api/login.service'
import type { Check, XStructuredCheck } from '../../api/check.service'
import type { Cart } from '../../cart-service.service'
import {
    CloudWatchLogsClient,
    CreateLogStreamCommand,
    PutLogEventsCommand,
} from '@aws-sdk/client-cloudwatch-logs'

import { CloudWatchLogsFactoryService } from '../cloud-watch-logs-factory/cloud-watch-logs-factory.service'
@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    private mdcData = {} as any

    private logger: CloudWatchLogsClient

    private readonly LOG_GROUP_NAME = 'WebPay'

    private readonly LOG_STREAM_KEY = 'log-stream-key'

    private logStreamName: string | null = null

    private shouldRetryLogCreate = true
    constructor(
        private storage: StorageMap,
        private device: DeviceDetectorService,
        private cloudWatchLogsFactory: CloudWatchLogsFactoryService
    ) {
        // this.storage.get('logging').subscribe((x) => {
        //     if (!!x) {
        //         this.mdcData = x
        //     }
        // })

        this.logger = this.cloudWatchLogsFactory.create()
    }

    clear() {
        delete this.mdcData.checkSeq
        delete this.mdcData.checkNum
        delete this.mdcData.checkId
        delete this.mdcData.location
        delete this.mdcData.table
        delete this.mdcData.rvcseq
        delete this.mdcData.code
        delete this.mdcData.orderId
        this.storage.set('logging', this.mdcData).subscribe()
    }

    addAnonUser(user: XLogin) {
        // this.mdcData.user = '' + user.id
        // this.storage.set('logging', this.mdcData).subscribe()
    }

    addUser(user: UserResponse) {
        // this.mdcData.user =
        //     '' + user.id + ' [' + user.firstname + ' ' + user.lastname + ']'
        // this.storage.set('logging', this.mdcData).subscribe()
    }

    addCheck(check: Check) {
        // this.mdcData.checkNum = check.check_num
        // this.mdcData.checkId = check.check_name
        // this.mdcData.table = check.table?.name
        // this.storage.set('logging', this.mdcData).subscribe()
    }

    addCheckStructured(check: XStructuredCheck) {
        // this.mdcData.checkSeq = check.checkSeq
        // this.mdcData.checkNum = check.checkNum
        // this.mdcData.checkId = check.checkName
        // this.mdcData.table = check.tableNum
        // this.storage.set('logging', this.mdcData).subscribe()
    }

    addCart(cart: Cart) {
        // this.mdcData.orderId = cart.uuid
        // if (!!cart.location) {
        //     this.mdcData.location = cart.location.name
        // }
        // this.storage.set('logging', this.mdcData).subscribe()
    }
    // Some endpoints like getLocationResultByLatLong return XLocation not new interface,
    // for now all types passed have an id and a name. Type could be improved later
    addLocation<T extends Pick<T, 'name'>>(loc: T) {
        // this.mdcData.location = loc.name
        // this.storage.set('logging', this.mdcData).subscribe()
    }

    addTable(tablenum: string) {
        // this.mdcData.table = tablenum
        // this.storage.set('logging', this.mdcData).subscribe()
    }

    addRvc(rvcseq: string) {
        // this.mdcData.rvcseq = rvcseq
        // this.storage.set('logging', this.mdcData).subscribe()
    }

    info(caller: string, msg: string, other?: any) {
        this.sendLog(msg)
    }

    warn(caller: string, msg: string, other?: any) {
        this.sendLog(msg)
    }

    error(caller: string, msg: string, other?: any) {
        this.sendLog(msg)
    }

    // pass some uuid to denote the stream
    public async createStream(streamId: string) {
        this.setlogStreamName = streamId
        this.logger.send(
            new CreateLogStreamCommand({
                logGroupName: this.LOG_GROUP_NAME,
                logStreamName: this.buildStreamName(streamId),
            })
        )
    }

    private set setlogStreamName(logStreamName: string) {
        this.logStreamName = logStreamName
        sessionStorage.setItem(this.LOG_STREAM_KEY, logStreamName)
    }

    public get getLogStreamId(): string | null {
        let streamKey = null
        if (this.logStreamName) {
            streamKey = this.logStreamName
        } else if (sessionStorage.getItem(this.LOG_STREAM_KEY)) {
            streamKey = sessionStorage.getItem(this.LOG_STREAM_KEY)
        }
        return streamKey
    }

    public async sendLog(message: string) {
        try {
            const d = await this.logger.send(
                new PutLogEventsCommand({
                    logEvents: [
                        {
                            timestamp: Date.now(),
                            message: JSON.stringify(message),
                        },
                    ],

                    logGroupName: this.LOG_GROUP_NAME,
                    logStreamName: this.buildStreamName(this.getLogStreamId),
                })
            )
        } catch (err) {
            // Logging didnt work for some reason.. try once more
            if (this.shouldRetryLogCreate) {
                this.createStream(this.getLogStreamId)
                this.shouldRetryLogCreate = false
            }
            await this.sendLog(message)
        }
    }

    // deprecated dont use
    private async log(
        caller: string,
        levelVal: string,
        msg: string,
        other: any = {}
    ) {
        // try {
        //     if (window.location.hostname === 'localhost') {
        //         return
        //     }
        //     const urlVal = window.location.href
        //     // if (
        //     //     msg.includes('FirebaseError') &&
        //     //     msg.includes('Cookies are not enabled')
        //     // ) {
        //     //     return
        //     // }
        //     // if (msg.includes('loggly.com')) {
        //     //     return
        //     // }
        //     // if (msg.includes('getcheck?code=') && msg.includes('404')) {
        //     //     return
        //     // }
        //     console.log(msg)
        //     this.mdcData.brand = environment.brand
        //     const deviceOSVal =
        //         this.device.os +
        //         (this.device.os_version !== 'unknown'
        //             ? ' ' + this.device.os_version
        //             : '')
        //     const deviceBrowserVal =
        //         this.device.browser + ' ' + this.device.browser_version
        //     const deviceTypeVal =
        //         (this.device.isDesktop() ? 'Desktop' : '') +
        //         (this.device.isTablet() ? 'Tablet' : '') +
        //         (this.device.isMobile() ? 'Mobile' : '')
        //     const data = {
        //         level: levelVal,
        //         mdc: this.mdcData,
        //         logger: caller,
        //         message: msg,
        //         xpoWeb: {
        //             path: urlVal,
        //             version: VERSION.version + '-' + VERSION.hash,
        //             specific: other,
        //             device: {
        //                 browser: deviceBrowserVal,
        //                 os: deviceOSVal,
        //                 brand: this.device.device,
        //                 type: deviceTypeVal,
        //                 res: window.innerWidth + 'x' + window.innerHeight,
        //             },
        //         },
        //     }
        //     const d = await this.cloudWatchLogger.send(
        //         new PutLogEventsCommand({
        //             logEvents: [
        //                 {
        //                     timestamp: Date.now(),
        //                     message: JSON.stringify(data.message, null, 2),
        //                 },
        //             ],
        //             logGroupName: this.LOG_GROUP_NAME,
        //             logStreamName: this.LOG_STREAM_NAME,
        //         })
        //     )
    }
    catch(e) {
        // no worries - Dont let logging stop us!
    }

    private buildStreamName(streamId: string) {
        const url = window.location.hostname
        return url + '-' + streamId
    }
}
