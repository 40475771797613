import { Injectable } from '@angular/core'
import { CloudWatchLogs } from '@aws-sdk/client-cloudwatch-logs'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'

@Injectable({
    providedIn: 'root',
})
export class CloudWatchLogsFactoryService {
    private readonly IDENTITY_POOL_ID: string =
        'us-west-2:8717ab85-4e76-47be-b9da-bd4f325b4cd0'
    private readonly COGNITO_REGION: string = 'us-west-2'
    private readonly LOGGER_REGION: string = 'us-west-2'

    public create(): CloudWatchLogs {
        return new CloudWatchLogs({
            credentials: fromCognitoIdentityPool({
                identityPoolId: this.IDENTITY_POOL_ID,
                clientConfig: {
                    region: this.COGNITO_REGION,
                },
            }),
            region: this.LOGGER_REGION,
        })
    }
}
