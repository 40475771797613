import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class UuidService {
    genUUID(): string {
        return this.genUUIDWithCount(5)
    }

    private genUUIDWithCount(count: number): string {
        const stringArr = []
        for (let i = 0; i < count; i++) {
            // eslint-disable-next-line no-bitwise
            const S4 = (((1 + Math.random()) * 0x10000) | 0)
                .toString(16)
                .substring(1)
            stringArr.push(S4)
        }
        return stringArr.join('-')
    }
}
