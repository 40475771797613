<mat-toolbar
    #nav
    color="{{ color }}"
    class="nav"
    [ngStyle]="{
        'justify-content': showLogo || open ? 'space-between' : 'flex-end'
    }"
>
    <app-logo [small]="true" *ngIf="showLogo || open"></app-logo>
    <div class="flex">
        <button
            mat-button
            (click)="sidenav.toggle()"
            class="nav__button"
            aria-label="'Toggle Menu'"
        >
            <mat-icon class="nav__button-icon" *ngIf="!open">menu</mat-icon>
            <mat-icon class="nav__button-icon" *ngIf="open">close</mat-icon>
        </button>
    </div>
    <ul class="nav__menu">
        <li class="nav__menu-link-wrapper">
            <a
                class="nav__menu-link"
                [routerLink]="'/pay'"
                [color]="currentModule === 'pay' ? null : 'transparent'"
                mat-raised-button
                >Pay</a
            >
        </li>
        <li class="nav__menu-link-wrapper">
            <a
                [routerLink]="'/transaction-history'"
                [color]="
                    currentModule === 'transaction-history'
                        ? null
                        : 'transparent'
                "
                mat-raised-button
                >Transaction History</a
            >
        </li>
        <ng-container *ngIf="!currentUser">
            <li class="nav__menu-link-wrapper">
                <a
                    [routerLink]="'/account/login'"
                    [color]="
                        currentModule === '/account/login'
                            ? null
                            : 'transparent'
                    "
                    mat-raised-button
                    >Log In</a
                >
            </li>
            <li class="nav__menu-link-wrapper">
                <a
                    [routerLink]="'/account/signup'"
                    [color]="currentModule === 'pay' ? null : '/account/signup'"
                    mat-raised-button
                    >Sign Up</a
                >
            </li>
        </ng-container>
        <ng-container *ngIf="currentUser">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>account_circle</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <div class="nav__user">
                    <h1 class="nav__user-name">
                        {{ currentUser.firstname }} {{ currentUser.lastname }}
                    </h1>
                    <h1 class="nav__email">{{ currentUser.email }}</h1>
                </div>
                <button
                    mat-menu-item
                    (click)="handleRedirect('/account/my_account')"
                >
                    My Account
                </button>
                <button mat-menu-item [matMenuTriggerFor]="privacy">
                    Privacy
                </button>
                <button mat-menu-item (click)="logout()">Log out</button>
            </mat-menu>
            <mat-menu #menu="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="privacy">
                    Privacy
                </button>
            </mat-menu>
            <mat-menu #privacy="matMenu">
                <a [href]="requestInfo" target="_top" mat-menu-item
                    >Request Account Information</a
                >
                <a [href]="requestDelete" target="_top" mat-menu-item
                    >Request Account Deletion</a
                >
            </mat-menu>
        </ng-container>
    </ul>
</mat-toolbar>
<mat-sidenav-container autosize [hasBackdrop]="false">
    <mat-sidenav
        #sidenav
        class="nav__sidebar"
        [fixedInViewport]="0"
        [fixedTopGap]="0"
        [fixedBottomGap]="0"
        position="end"
        mode="over"
    >
        <aside class="nav__content">
            <h1 class="nav__heading">{{ title1 }}</h1>
            <h1 *ngIf="currentUser">
                {{ currentUser.firstname }} {{ currentUser.lastname }}
            </h1>
            <ul class="nav__options">
                <a
                    [routerLink]="'/pay'"
                    [color]="currentModule === 'pay' ? null : 'transparent'"
                    mat-raised-button
                >
                    <li>Pay Bill</li>
                </a>
                <a
                    [routerLink]="'/transaction-history'"
                    [color]="
                        currentModule === 'transaction-history'
                            ? null
                            : 'transparent'
                    "
                    mat-raised-button
                >
                    <li>Transaction History</li>
                </a>
                <ng-container *ngIf="!currentUser">
                    <a
                        [routerLink]="'/account/signup'"
                        [color]="
                            currentModule === '/account/signup'
                                ? null
                                : 'transparent'
                        "
                        mat-raised-button
                    >
                        <li>Sign Up</li>
                    </a>
                    <a
                        [routerLink]="'/account/login'"
                        [color]="
                            currentModule === '/account/login'
                                ? null
                                : 'transparent'
                        "
                        mat-raised-button
                    >
                        <li>Log In</li>
                    </a>
                </ng-container>
                <ng-container *ngIf="currentUser">
                    <button
                        mat-raised-button
                        color="transparent"
                        [matMenuTriggerFor]="account"
                    >
                        Account
                    </button>
                    <mat-menu #account="matMenu">
                        <a mat-menu-item [routerLink]="'/account/my_account'">
                            My Account
                        </a>
                        <button mat-menu-item [matMenuTriggerFor]="privacy">
                            Privacy
                        </button>
                        <button mat-menu-item (click)="logout()">
                            Log Out
                        </button>
                    </mat-menu>
                    <mat-menu #privacy="matMenu">
                        <a [href]="requestInfo" target="_top" mat-menu-item
                            >Request Account Information</a
                        >
                        <a [href]="requestDelete" target="_top" mat-menu-item
                            >Request Account Deletion</a
                        >
                    </mat-menu>
                </ng-container>
            </ul>
        </aside>
    </mat-sidenav>
    <mat-sidenav-content><ng-content></ng-content></mat-sidenav-content>
</mat-sidenav-container>
