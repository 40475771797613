import { Injectable } from '@angular/core'
import { Observable, tap } from 'rxjs'
import { LoggingService } from '../services/logging/logging.service'
import { API_ROUTES } from './api-routes'
import { APIServiceService } from './apiservice.service'
import { UserResponse } from './login.service'
import { MutationResponse } from './transaction.service'
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private api: APIServiceService,
        private logger: LoggingService
    ) {}

    requestResetPasswordLink(
        data: ResetPasswordRequest
    ): Observable<MutationResponse<string>> {
        const url = API_ROUTES.public.passwordReset
        return this.api.post<MutationResponse<string>>(url, data).pipe(
            tap((response) => {
                this.logger.info(
                    'AuthService',
                    'Reset Password' + response.success
                )
            })
        )
    }

    updatePassword(
        data: UpdatePasswordRequest
    ): Observable<MutationResponse<string>> {
        const url = API_ROUTES.public.passwordUpdate
        return this.api.post<MutationResponse<string>>(url, { user: data })
    }

    logout(): Observable<LogoutResponse> {
        const url = API_ROUTES.v0.logout
        return this.api.get<LogoutResponse>(url).pipe(
            tap((response) => {
                this.logger.info('AuthService', 'Logout' + response.status)
            })
        )
    }

    createAccount(
        createAccountData: CreateAccountRequest
    ): Observable<UserResponse> {
        const url = API_ROUTES.v1.createAccount
        return this.api.post<UserResponse>(url, createAccountData).pipe(
            tap((response) => {
                this.logger.info('AuthService', 'Create Account' + response)
            })
        )
    }
}

interface LogoutResponse {
    status: string
}

export interface CreateAccountRequest {
    brand: string
    email: string
    phone: string
    password: string
    firstname: string
    lastname: string
    timezone: string
    offers: number //? Subscribe to email offers 1 = sub, anything else is dont sub
}

interface ResetPasswordRequest {
    email: string
    brand: string
    reset_password_url: string
}

export interface UpdatePasswordRequest {
    reset_password_token: string
    password: string
    password_confirmation: string
}
