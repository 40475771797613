import { Component, Inject, OnInit } from '@angular/core'
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'
import { environment } from 'src/environments/environment'

@Component({
    selector: 'app-terms-snack',
    templateUrl: './terms-snack.component.html',
    styleUrls: ['./terms-snack.component.scss'],
})
export class TermsSnackComponent {
    terms = environment.terms

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public snackbar: MatSnackBar
    ) {}

    accept() {
        localStorage.setItem('accepted-policy', 'true')
        this.snackbar.dismiss()
    }
}
